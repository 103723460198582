import Send from '../../schedule.client.js'

//주석정의방법 : 컨트롤러 메소드명 - 메소드에 대한 간단 주석 - REST URI
export default {
  // searchLegScheduleForm - 스케줄 > 구간화면 - GET /schedule/leg/search-scheduleform
  getLegScheduleForm () {
    return Send({
      url: '/schedule/leg/search-scheduleform',
      method: 'get'
    })
  },
  // searchLegSchedule - 구간별 스케줄 조회 - GET /schedule/leg/search-schedule
  getLegSchedule (params) {
    return Send({
      url: '/schedule/leg/search-schedule',
      method: 'get',
      params: params
    })
  },
  // searchLegScheduleDetail2 - 선택 스케줄 상세정보 - GET /schedule/leg/search-schedule-detail2
  getLegScheduleDetail (params) {
    return Send({
      url: '/schedule/leg/search-schedule-detail2',
      method: 'get',
      params: params
    })
  },
  // getSecondVessel - 2nd Vessel 조회 - GET /schedule/vessel/search-2nd-vessel
  get2ndVessel (params) {
    return Send({
      url: '/schedule/vessel/search-2nd-vessel',
      method: 'get',
      params: params
    })
  },
  // isHolidayYn - 휴일 유무 체크 - GET /schedule/leg/is-holiday
  getHolidayYn (day) {
    return Send({
      url: '/schedule/leg/is-holiday',
      method: 'get',
      params: {
        day: day
      }
    })
  },
  // insertWB242H - WEB 스케줄 조회 로그 저장 - POST /schedule/leg/insert-WB242H
  insertWB242H (param) {
    return Send({
      url: '/schedule/leg/insert-WB242H',
      method: 'post',
      params: param
    })
  },
  // scheduleRestriction - 부킹 제약사항체크 - GET /schedule/leg/schedule-restriction
  checkBkgRestriction (params) {
    return Send({
      url: '/schedule/leg/schedule-restriction',
      method: 'get',
      params: params
    })
  },
  // excelDownloadLeg - 구간별 스케줄 Excel 출력 - GET /schedule/leg/excel-downloadLeg
  excelDownloadLeg (params) {
    return Send({
      url: '/schedule/leg/excel-downloadLeg',
      method: 'get',
      params: params,
      responseType: 'blob'
    })
  },
  // findSA271Yn - 부킹 넘어 가기전에 실적 체크(최근 7일간 운임견적 확인 유무) - GET /schedule/leg/find-SA271Yn
  findSA271Yn (params) {
    return Send({
      url: '/schedule/leg/find-SA271Yn',
      method: 'get',
      params: params
    })
  },
  // popFreAppNo - 스케줄 R/A No 조회 - GET /schedule/leg/pop-fre-app-no
  popFreAppNo (params) {
    return Send({
      url: '/schedule/leg/pop-fre-app-no',
      method: 'get',
      params: params
    })
  },
  // popFreSurcharge - 견적 운임 조회 - GET /schedule/leg/pop-fre-surcharge
  popFreSurcharge (params) {
    return Send({
      url: '/schedule/leg/pop-fre-surcharge',
      method: 'get',
      params: params
    })
  },
  // popSearchScheduleResult_bkg - 구간별 스케줄 검색 결과(Booking 전용) - GET /schedule/leg/pop-search-schedule-sesult-bkg
  popSearchScheduleBkg (params) {
    return Send({
      url: '/schedule/leg/pop-search-schedule-sesult-bkg',
      method: 'get',
      params: params
    })
  },
  getLegDetail (bkgNo) {
    return Send({
      url: '/schedule/leg/' + bkgNo + '/leg-detail',
      method: 'get'
    })
  },
  // 영업사원조회 - GET /schedule/leg/find-sal-uno
  findSalUno (params) {
    return Send({
      url: '/schedule/leg/find-sal-uno',
      method: 'get',
      params: params
    })
  },
  // 운임 > 나의운임 리스트 조회 - GET /schedule/freight/my-freight
  myOceanFreight (params) {
    return Send({
      url: '/schedule/freight/my-freight',
      method: 'get',
      params: params
    })
  },
  // 운임 > 나의운임 > 연장요청 - POST /schedule/freight/validity-request
  validityRequest (params) {
    return Send({
      url: '/schedule/freight/validity-request',
      method: 'post',
      data: params
    })
  },
  // 운임검색 LOG INSERT(해외) - POST /schedule/leg/pop-find-price
  popFindPrice (params) {
    return Send({
      url: '/schedule/leg/pop-find-price',
      method: 'post',
      data: params
    })
  },
  // 운임요청(R/M) - GET /schedule/leg/request-rate-manage
  fnPopFreReq (params) {
    return Send({
      url: '/schedule/leg/request-rate-manage',
      method: 'get',
      params: params
    })
  },
  // 선박 지연 여부 - GET /schedule/leg/delay-reason
  getVslDelayReason (params) {
    return Send({
      url: '/schedule/vessel/delay-reason',
      method: 'get',
      params: params
    })
  },
  // 핫 스팟 대상 조회
  getKmtcSpotTarget (menuCd) {
    return Send({
      url: '/schedule/leg/kmtc-spot/' + menuCd,
      method: 'get'
     })
  },
  // 영업사원 메일 보내기 - POST /schedule/leg/pop-find-price
  sendSalEmail (params) {
    return Send({
      url: '/schedule/leg/sal-uno-email',
      method: 'post',
      data: params
    })
  },
  // KMTC ON 도착지 국가 정보 조회
  getKmtcOnCtrPlcInfo (portKind) {
    return Send({
      url: '/schedule/leg/getKmtcOnCtrPlcInfo/' + portKind,
      method: 'get'
    })
  },
  // 운임 > 나의운임(KMTC ON PREMIUM) 리스트 조회 - GET /schedule/freight/my-freight-premium
  myOceanFreightPremium (params) {
    return Send({
      url: '/schedule/freight/my-freight-premium',
      method: 'get',
      params: params
    })
  },
  // KMTC ON > 나의운임(KMTC ON PREMIUM) > KMTC ON NEGO 제출하기
  kmtcOnNegoRequest (params) {
    return Send({
      url: '/schedule/freight/kmtcon-nego-request',
      method: 'get',
      params: params
    })
  },
  // 운임 > fak운임
  fakOceanFreight (params) {
    return Send({
      url: '/schedule/freight/fak-freight',
      method: 'get',
      params: params
    })
  },
  // 운임 > fak운임 > 기준날짜 조회
  fetchBascDtList (params) {
    return Send({
      url: '/schedule/freight/basc-dt-list',
      method: 'get',
      params: params
    })
  },
  // 운임 > 나의쿠폰 리스트 조회
  getMyCouponList (params) {
    return Send({
      url: '/schedule/freight/my-coupon',
      method: 'get',
      params: params
    })
  },
  // FDR 정보 조회
  getfdrInfo (blNo) {
    return Send({
      url: '/schedule/leg/fdrInfo/' + blNo,
      method: 'get'
    })
  },
  getScheduleNotiInfo () {
    return Send({
      url: '/schedule/leg/getScheduleNotiInfo',
      method: 'get'
    })
  },
  saveScheduleNotiInfo (params, mode) {
    const method = mode === 'I' ? 'post' : 'put'

    return Send({
      url: '/schedule/leg/saveScheduleNotiInfo',
      params,
      method
    })
  },
  getFakFreightSectionList (params) {
    return Send({
      url: 'schedule/freight/fak-freight-section-list',
      method: 'get',
      params: params
    })
  },
  getFakOpnYn (params) {
    return Send({
      url: 'schedule/freight/get-fak-opn-yn',
      method: 'get',
      params: params
    })
  }
}
