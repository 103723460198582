var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: {
        action: _vm.klnetUrl,
        "accept-charset": "utf-8",
        method: "post",
        name: "frmPayment",
        id: "frmPayment",
        target: "iframe_payment",
      },
    },
    [
      _c("input", {
        attrs: { type: "hidden", id: "STOREID", name: "STOREID" },
        domProps: { value: _vm.vi.storeid },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "STORE_NAME", name: "STORE_NAME" },
        domProps: { value: _vm.vi.storeName },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "BUSINESS_NO", name: "BUSINESS_NO" },
        domProps: { value: _vm.vi.businessNo },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "CRYPTO_KEY", name: "CRYPTO_KEY" },
        domProps: { value: _vm.vi.cryptoKey },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "STORE_URL", name: "STORE_URL" },
        domProps: { value: _vm.vi.storeUrl },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "TX_USER_DEFINE", name: "TX_USER_DEFINE" },
        domProps: { value: _vm.vi.txUserDefine },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "TRAN_NO", name: "TRAN_NO" },
        domProps: { value: _vm.vi.tranNo },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "PRODUCTTYPE", name: "PRODUCT_TYPE" },
        domProps: { value: _vm.vi.productType },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "GOODS_NAME", name: "GOODS_NAME" },
        domProps: { value: _vm.vi.goodsName },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "AMT", name: "AMT" },
        domProps: { value: _vm.vi.amt },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "CURRENCY", name: "CURRENCY" },
        domProps: { value: _vm.vi.currency },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "QUANTITY", name: "QUANTITY" },
        domProps: { value: _vm.vi.quantity },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "SALE_DATE", name: "SALE_DATE" },
        domProps: { value: _vm.vi.saleDate },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "CUSTOMER_NAME", name: "CUSTOMER_NAME" },
        domProps: { value: _vm.vi.customerName },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "CUSTOMER_EMAIL", name: "CUSTOMER_EMAIL" },
        domProps: { value: _vm.vi.customerEmail },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "CUSTOMER_TEL", name: "CUSTOMER_TEL" },
        domProps: { value: _vm.vi.customerTel },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "CUSTOMER_IP", name: "CUSTOMER_IP" },
        domProps: { value: _vm.vi.customerIp },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "NOTICE_URL", name: "NOTICE_URL" },
        domProps: { value: _vm.vi.noticeUrl },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "RETURN_URL", name: "RETURN_URL" },
        domProps: { value: _vm.vi.returnUrl },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "COMPLETE_URL", name: "COMPLETE_URL" },
        domProps: { value: _vm.vi.completeUrl },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "CANCEL_URL", name: "CANCEL_URL" },
        domProps: { value: _vm.vi.cancelUrl },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "TRADE_TYPE", name: "TRAN_TYPE" },
        domProps: { value: _vm.vi.tranType },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "ETC_DATA1", name: "ETC_DATA1" },
        domProps: { value: _vm.vi.etcData1 },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "ETC_DATA2", name: "ETC_DATA2" },
        domProps: { value: _vm.vi.etcData2 },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "ETC_DATA3", name: "ETC_DATA3" },
        domProps: { value: _vm.vi.etcData3 },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "BANK_CD", name: "BANK_CD" },
        domProps: { value: _vm.vi.bankCd },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "LIMIT_YN", name: " LIMIT_YN" },
        domProps: { value: _vm.vi.limitYn },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "LIMIT_NAME", name: "LIMIT_NAME" },
        domProps: { value: _vm.vi.limitName },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "REQ_NAME", name: "BANK_CD" },
        domProps: { value: _vm.vi.reqName },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "START_DATE", name: "START_DATE" },
        domProps: { value: _vm.vi.startDate },
      }),
      _c("input", {
        attrs: { type: "hidden", id: "END_DATE", name: "END_DATE" },
        domProps: { value: _vm.vi.endDate },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }