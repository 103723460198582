<template>
  <body class="main">
    <!-- 가로 배너 시작 -->
    <!-- KMTC ON C등급 배너 -->
    <div class="main_banner" v-if="auth.login && kmtcSpotUserYn === 'Y' && kmtcSpotCNegoYn === 'Y'">
      <button v-if="isShow.close" class="layer_close" @click="mainBannerClose()">close</button>
      <p v-if="isShow.text" class="text_desc">
        <a v-if="isShow.link" href="#" @click.prevent="clickBanner('kmtconC')">
          <img src="../assets/images/main/bell.png" class="bell_img">&nbsp;&nbsp;<span v-html="$t('msg.MAIN010G030.040')"></span></a>
      </p>
    </div>
    <!-- KMTC ON D등급 배너 -->
    <div class="main_banner" v-if="auth.login && kmtcSpotUserYn === 'Y' && kmtcSpotNegoYn === 'Y'">
      <button v-if="isShow.close" class="layer_close" @click="mainBannerClose()">close</button>
      <p v-if="isShow.text" class="text_desc">
        <a v-if="isShow.link" href="#" @click.prevent="clickBanner('kmtcon')">
          <img src="../assets/images/main/bell.png" class="bell_img">&nbsp;&nbsp;<span v-html="$t('msg.MAIN010G030.036')"></span></a>
      </p>
    </div>
    <!-- 가로 배너 끝 -->
    <div class="wrap" @click="quickMenuClose($event)">
      <win-layer-pop class="main_inquiry_pop">
        <component
          v-if="customComponent"
          :is="customComponent"
          @close="closePopup"
          :parent-info="popupParams"
        />
      </win-layer-pop>
      <div class="container_main">
        <!--   ##### container_main  ##### -->
        <!-- 메인화면 우측 하단 배너 용도
        <div style="position:absolute; top:740px; left: 1410px; z-index:10; width:480px; height:175px; border:1px solid #C9CBD6; background:#ffffff;">
          <a href="#" @click.prevent="clickBanner('schedule')">
            <img src = "../assets/images/main/banner_online_KR.png">
          </a>
        </div> -->
        <div class="quick_section">
          <!--  quick_area -->
          <form id="frm_quick">
            <p class="txt">
              <span>{{ $t("msg.MAIN010G030.001") }}</span>
            </p>
            <!-- QUICK -->
            <ul>
              <li v-if="serviceCtrCd === 'KR'">
                <!-- <li v-if="false"> -->
                <a href="#" @click.prevent="quickMenuOpen('pickup')">
                  <span class="quick_icon quick01"></span>
                  <span v-html="$t('msg.MAIN010G030.002')" /><!-- 픽업지<br />변경 -->
                </a>
                <div class="quick_sub" :style="{display: [quickMenu.pickup.openFlag ? 'block' : 'none'],}">
                  <!--quick_sub -->
                  <p class="icon" @click.prevent="quickMenuOpen('pickup')">
                    <span class="quick_icon quick01"></span>
                  </p>
                  <p class="cont">
                    <span class="tit_label">{{ $t("msg.MAIN010G030.003") }}</span><!-- 픽업지 변경 -->
                    <span class="sch_form">
                      <input
                        v-model="quickMenu.pickup.keyword"
                        id="pickup_keyword"
                        type="text"
                        :placeholder="$t('msg.MAIN010G030.004')"
                        @input="quickMenu.pickup.keyword=quickMenu.pickup.keyword.toUpperCase()"
                      /><!-- Booking No. 입력 -->
                      <a class="button blue" href="#" @click.prevent="searchByQuickMenu('pickup')">{{ $t("msg.MAIN010G030.005") }}</a><!-- 검색 -->
                    </span>
                  </p>
                  <p class="sub_close">
                    <a href="#" class="sub_close" @click.prevent="quickMenuOpen('pickup')">닫기</a>
                  </p>
                </div>
                <!--quick_sub // -->
              </li>
              <li>
                <a href="#" @click.prevent="quickMenuOpen('demdet')">
                  <span class="quick_icon quick02"></span>
                  <span v-html="$t('msg.MAIN010G030.006')" /><!-- DEM/DET<br />조회 -->
                </a>
                <div class="quick_sub" :style="{display: [quickMenu.demdet.openFlag ? 'block' : 'none'],}">
                  <!--quick_sub -->
                  <p class="icon" @click.prevent="quickMenuOpen('demdet')">
                    <span class="quick_icon quick02"></span>
                  </p>
                  <p class="cont">
                    <span class="tit_label">{{ $t("msg.MAIN010G030.007") }}</span><!-- DEM/DET 조회 -->
                    <span class="ei-cat-cd-radio">
                      <input
                        type="radio"
                        id="quick-demdet-O"
                        value="O"
                        v-model="eiCatCdRadio"
                      />
                      <label for="quick-demdet-O"><span></span>{{ $t("msg.PERS010L022.003") }}</label>
                      <!-- 수출 -->
                    </span>
                    <span class="ei-cat-cd-radio">
                      <input
                        type="radio"
                        id="quick-demdet-I"
                        value="I"
                        v-model="eiCatCdRadio"
                      />
                      <label for="quick-demdet-I"><span></span>{{ $t("msg.PERS010L022.012") }}</label>
                      <!-- 수입 -->
                    </span>
                    <span class="sch_form">
                      <input
                        v-model="quickMenu.demdet.keyword"
                        id="demdet_keyword"
                        type="text"
                        :placeholder="$t('msg.MAIN010G030.008')"
                        @input="quickMenu.demdet.keyword=quickMenu.demdet.keyword.toUpperCase()"
                      /><!-- B/L No. / Container No. 입력 -->
                      <a class="button blue" href="#" @click.prevent="searchByQuickMenu('demdet')">{{ $t("msg.MAIN010G030.009") }}</a><!-- 검색 -->
                    </span>
                  </p>
                  <p class="sub_close">
                    <a href="#" class="sub_close" @click.prevent="quickMenuOpen('demdet')">닫기</a>
                  </p>
                </div>
                <!--quick_sub // -->
              </li>
              <li>
                <a href="#" @click.prevent="quickMenuOpen('payment')">
                  <span class="quick_icon quick03"></span>
                  <!--                  <span v-html="'D/O ' + $t('msg.PERS010L020.019')" />--><!-- D/O 발행현황 -->
                  <span v-html="$t('msg.ONIM050G010.001')" /><!-- D/O 발행 & B/L 현황 -->
                </a>
                <div class="quick_sub" :style="{display: [quickMenu.payment.openFlag ? 'block' : 'none'],}">
                  <!--quick_sub -->
                  <p class="icon" @click.prevent="quickMenuOpen('payment')">
                    <span class="quick_icon quick03"></span>
                  </p>
                  <p class="cont">
                    <!--                    <span class="tit_label">{{ "D/O " + $t("msg.PERS010L020.019") }}</span>--><!-- D/O 발행현황 -->
                    <span class="tit_label">{{ $t("msg.ONIM050G010.001") }}</span><!-- D/O 발행 & B/L 현황 -->
                    <span class="sch_form">
                      <input
                        v-model="quickMenu.payment.keyword"
                        id="payment_keyword"
                        type="text"
                        :placeholder="$t('msg.MAIN010G030.012')"
                        @input="quickMenu.payment.keyword=quickMenu.payment.keyword.toUpperCase()"
                      /><!-- B/L No 입력 -->
                      <a class="button blue" href="#" @click.prevent="searchByQuickMenu('payment')">{{ $t("msg.MAIN010G030.013") }}</a><!-- 검색 -->
                    </span>
                  </p>
                  <p class="sub_close">
                    <a href="#" class="sub_close" @click.prevent="quickMenuOpen('payment')">닫기</a>
                  </p>
                </div>
                <!--quick_sub // -->
              </li>
              <li v-if="serviceCtrCd === 'KR'">
                <a href="#" @click.prevent="quickMenuOpen('return')">
                  <span class="quick_icon quick04"></span>
                  <span>{{ $t("msg.MAIN010G030.014") }}</span><!-- 반납 -->
                </a>
                <div class="quick_sub" :style="{display: [quickMenu.return.openFlag ? 'block' : 'none'],}">
                  <!--quick_sub -->
                  <p class="icon" @click.prevent="quickMenuOpen('return')">
                    <span class="quick_icon quick04"></span>
                  </p>
                  <p class="cont">
                    <span class="tit_label">{{ $t("msg.MAIN010G030.014") }}</span><!-- 반납 -->
                    <span class="sch_form">
                      <input
                        v-model="quickMenu.return.keyword"
                        id="return_keyword"
                        type="text"
                        :placeholder="$t('msg.MAIN010G030.015')"
                        @input="quickMenu.return.keyword=quickMenu.return.keyword.toUpperCase()"
                      /><!-- B/L No. / Container No. 입력 -->
                      <a class="button blue" href="#" @click.prevent="searchByQuickMenu('return')">{{ $t("msg.MAIN010G030.016") }}</a><!-- 검색 -->
                    </span>
                  </p>
                  <p class="sub_close">
                    <a class="sub_close" href="#" @click.prevent="quickMenuOpen('return')">닫기</a>
                  </p>
                </div>
                <!--quick_sub // -->
              </li>
              <li>
                <a href="#" @click.prevent="quickMenuOpen('cntrTareWeight')">
                  <span class="quick_icon quick06 "></span>
                  <span v-html="$t('CNTR Tare Weight')" /><!-- CNTR Tare Weight -->
                </a>
                <div class="quick_sub" :style="{display: [quickMenu.cntrTareWeight.openFlag ? 'block' : 'none'],}">
                  <!--quick_sub -->
                  <p class="icon" @click.prevent="quickMenuOpen('cntrTareWeight')">
                    <span class="quick_icon quick06"></span>
                  </p>
                  <p class="cont">
                    <span class="tit_label">{{ $t("CNTR Tare Weight") }}</span>
                    <span class="sch_form">
                      <input
                        v-model="quickMenu.cntrTareWeight.keyword"
                        id="cntrTareWeight_keyword"
                        type="text"
                        :placeholder="$t('msg.MAIN010G030.008')"
                        @input="quickMenu.cntrTareWeight.keyword=quickMenu.cntrTareWeight.keyword.toUpperCase()"
                      /><!-- B/L No. / Container No. 입력 -->
                      <a class="button blue" href="#" @click.prevent="searchByQuickMenu('cntrTareWeight')">{{ $t("msg.MAIN010G030.016") }}</a><!-- 검색 -->
                    </span>
                  </p>
                  <p class="sub_close">
                    <a class="sub_close" href="#" @click.prevent="quickMenuOpen('cntrTareWeight')">닫기</a>
                  </p>
                </div>
                <!--quick_sub // -->
              </li>
              <li>
                <a href="#" @click.prevent="quickMenuOpen('lCharge')">
                  <span class="quick_icon quick05"></span>
                  <span v-html="$t('Local Charge')" /><!-- D/O 발행 & B/L 현황 -->
                </a>
              </li>
            </ul>
          </form>
        </div>
        <!--  quick_section  // -->

        <div class="content_main">
          <!-- content_main : -->
          <div class="visual_area">
            <!-- visual_area -->
            <div class="scroll_btn">
              <a href="#notice_area">스크롤 버튼</a>
              <!-- id="notice_area"는 임시 테스트용 개발시 개발에 맞는 id로 변경해서 사용하세요. -->
            </div>
            <form id="frm_main">
              <div class="main_cont">
                <!-- 스케줄 화물추적 운임문의 -->
                <div class="cont">
                  <!-- cont -->
                  <div v-if="searchFlag === 'S'" class="open">
                    <span class="tit_label">{{ $t("msg.MAIN010G030.017") }}</span><!-- 스케줄 -->
                    <div class="sch_form">
                      <p class="box d_inline_flex">
                        <span class="tit" style="cursor: pointer; margin-top: 2px;" @click.prevent="fnFocusInput('polPol')">{{ $t("msg.MAIN010G030.018") }}</span><!-- 출발 -->
                        <e-auto-complete-place
                          id="polPol"
                          class="w-100"
                          @change="changePol"
                          :ctr-cd="scheduleForm.startCtrCd"
                          :plc-cd="scheduleForm.startPlcCd"
                          :is-char="true"
                          :is-char-alert="true"
                          :list-group-width="autoCompleteWidth"
                        />
                      </p>
                      <p class="box d_inline_flex">
                        <span class="tit" style="cursor: pointer; margin-top: 2px;" @click.prevent="fnFocusInput('podPod')">{{ $t("msg.MAIN010G030.019") }}</span><!-- 도착 -->
                        <e-auto-complete-place
                          id="podPod"
                          class="w-100"
                          @change="changePod"
                          :ctr-cd="scheduleForm.destCtrCd"
                          :plc-cd="scheduleForm.destPlcCd"
                          :is-char="true"
                          :is-char-alert="true"
                          :list-group-width="autoCompleteWidth"
                        />
                      </p>
                      <a class="button blue" href="#" @click.prevent="goSchedule()">{{ $t("msg.MAIN010G030.020") }}</a><!-- 검색 -->
                    </div>
                  </div>

                  <div v-if="searchFlag !== 'S'" class="off" @click.prevent="chgSearchMenu('S')">
                    <a href="#" class="link_icon schdule">{{ $t("msg.MAIN010G030.017") }}</a><!-- 스케줄 -->
                  </div>
                </div>
                <!-- cont // -->

                <div class="cont">
                  <!-- cont -->
                  <div v-if="searchFlag === 'C'" class="open" style="display: block">
                    <span class="tit_label">{{ $t("msg.MAIN010G030.021") }}</span><!-- 화물추적 -->
                    <div class="sch_form case2">
                      <p class="box">
                        <span>
                          <input
                            v-model="cargoTrackingForm.keyword"
                            id="cargoKeyword"
                            type="text"
                            :placeholder="$t('msg.MAIN010G030.022')"
                            @input="cargoTrackingForm.keyword=cargoTrackingForm.keyword.toUpperCase()"
                          /><!-- B/L No. / Container No. / Booking No. 입력 -->
                        </span>
                      </p>
                      <a class="button blue" href="#" @click.prevent="goCargoTracking()">{{ $t("msg.MAIN010G030.020") }}</a><!-- 검색 -->
                    </div>
                  </div>

                  <div v-if="searchFlag !== 'C'" class="off" @click.prevent="chgSearchMenu('C')">
                    <a href="#" class="link_icon cargo">{{ $t("msg.MAIN010G030.021") }}</a><!-- 화물추적 -->
                  </div>
                </div>
                <!-- cont // -->

                <div class="cont">
                  <!-- cont -->
                  <div v-if="searchFlag === 'SC'" class="open">
                    <span class="tit_label">{{ $t("msg.MAIN010G030.024") }}</span><!-- 운임문의 -->
                    <div class="sch_form">
                      <p class="box d_inline_flex">
                        <span class="tit" style="cursor: pointer; margin-top: 2px" @click.prevent="fnFocusInput('polPol2')">{{ $t("msg.MAIN010G030.025") }}</span><!-- 출발 -->
                        <e-auto-complete-place
                          id="polPol2"
                          class="w-100"
                          @change="changePol2"
                          :ctr-cd="shippingCostForm.porCtrCd"
                          :plc-cd="shippingCostForm.porPlcCd"
                          :is-char="true"
                          :is-char-alert="true"
                          :list-group-width="autoCompleteWidth"
                        />
                      </p>
                      <p class="box d_inline_flex">
                        <span class="tit" style="cursor: pointer; margin-top: 2px" @click.prevent="fnFocusInput('podPod2')">{{ $t("msg.MAIN010G030.026") }}</span><!-- 도착 -->
                        <e-auto-complete-place
                          id="podPod2"
                          class="w-100"
                          @change="changePod2"
                          :ctr-cd="shippingCostForm.dlyCtrCd"
                          :plc-cd="shippingCostForm.dlyPlcCd"
                          :is-char="true"
                          :is-char-alert="true"
                          :list-group-width="autoCompleteWidth"
                        />
                      </p>
                      <a class="button blue" href="#" @click.prevent="fnSearchSurCharge()">{{ $t("msg.MAIN010G030.027") }}</a>
                    </div>
                  </div>

                  <div v-if="searchFlag !== 'SC'" class="off" @click.prevent="chgSearchMenu('SC')">
                    <a href="#" class="link_icon fare">{{ $t("msg.MAIN010G030.024") }}</a><!-- 운임문의 -->
                  </div>
                </div>

                <!--                <div v-if="auth.login && kmtcSpotUserYn === 'Y'" class="cont">-->
                <!--                  &lt;!&ndash; cont &ndash;&gt;-->
                <!--                  <div v-if="searchFlag === 'KS'" class="open">-->
                <!--                    <span class="tit_label">{{ $t("msg.MAIN010G030.038") }}</span>&lt;!&ndash; KMTC SPOT &ndash;&gt;-->
                <!--                    <div class="sch_form">-->
                <!--                      <p class="box d_inline_flex">-->
                <!--                        <span class="tit" style="cursor: pointer" @click.prevent="fnFocusInput('polPol3')">{{ $t("msg.MAIN010G030.025") }}</span>&lt;!&ndash; 출발 &ndash;&gt;-->
                <!--                        <e-auto-complete-place-->
                <!--                          id="polPol3"-->
                <!--                          class="w-100"-->
                <!--                          @change="changePol3"-->
                <!--                          :ctr-cd="scheduleKmtcSpotForm.startCtrCd"-->
                <!--                          :plc-cd="scheduleKmtcSpotForm.startPlcCd"-->
                <!--                          :is-char="true"-->
                <!--                          :is-char-alert="true"-->
                <!--                          :list-group-width="autoCompleteWidth"-->
                <!--                        />-->
                <!--                      </p>-->
                <!--                      <p class="box d_inline_flex">-->
                <!--                        <span class="tit" style="cursor: pointer" @click.prevent="fnFocusInput('podPod3')">{{ $t("msg.MAIN010G030.026") }}</span>&lt;!&ndash; 도착 &ndash;&gt;-->
                <!--                        <e-auto-complete-place-->
                <!--                          id="podPod3"-->
                <!--                          class="w-100"-->
                <!--                          @change="changePod3"-->
                <!--                          :ctr-cd="scheduleKmtcSpotForm.destCtrCd"-->
                <!--                          :plc-cd="scheduleKmtcSpotForm.destPlcCd"-->
                <!--                          :is-char="true"-->
                <!--                          :is-char-alert="true"-->
                <!--                          :list-group-width="autoCompleteWidth"-->
                <!--                        />-->
                <!--                      </p>-->
                <!--                      <a class="button blue" href="#" @click.prevent="goScheduleKmtcSpot('03')">{{ $t("msg.MAIN010G030.027") }}</a>-->
                <!--                    </div>-->
                <!--                  </div>-->

                <!--                  <div v-if="searchFlag !== 'KS'" class="off">-->
                <!--                    &lt;!&ndash;@click.prevent="chgSearchMenu('KS')"> 출발/도착 입력 사용시&ndash;&gt;-->
                <!--                    <div style="text-align: center; margin-top: 7px; color: red; font-weight: bolder ">HOT DEAL !</div>-->
                <!--                    <a href="#" class="link_icon hotspot" @click.prevent="goScheduleKmtcSpot('03')" style="margin-top: 0px">{{ $t("msg.MAIN010G030.038") }}</a>&lt;!&ndash; KMTC SPOT &ndash;&gt;-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
              <!-- 스케줄 화물추적 운임문의  // -->
            </form>
          </div>
          <!-- visual_area // -->

          <div
            v-if="noticeList !== undefined && noticeList.length > 0"
            class="notice_area"
            id="notice_area"
          >
            <!-- notice_area  :  id="notice_area"는 임시 테스트용 개발시 개발에 맞는 id로 변경해서 사용하세요.  -->
            <div class="title">
              <h1>{{ $t("msg.MAIN010G030.028") }}</h1>
              <!-- 공지사항 -->
              <a
                href="#"
                class="more"
                @click.prevent="
                  $router.push({ name: 'notice' }).catch(() => {})
                "
              >{{ $t("msg.MAIN010G030.029") }}</a><!-- 모두보기 -->
            </div>

            <dl
              v-for="(notice, idx) in noticeList"
              :key="`${notice.noticeId}_${idx}`"
              :class="[idx === 0 ? 'first' : '']"
              @click.prevent="
                $router.push({ name: 'notice', query: notice }).catch(() => {})
              "
            >
              <dt>
                <div class="text_overflow">{{ notice.title }}</div>
              </dt>
              <dd>
                <div class="text_overflow" v-html="notice.content" />
              </dd>
              <dd class="date">{{ notice.fstEntDtm }}</dd>
            </dl>
          </div>
          <!-- notice_area // -->

          <div class="banner_area">
            <!-- banner area -->
            <div class="service">
              <!-- service -->
              <div class="slick_ban">
                <!-- slick_ban -->
                <dl
                  @click="clickBanner('mobile')"
                  class="banner11"
                  :class="{
                    KR: auth.serviceLang === 'KOR',
                    EN: auth.serviceLang === 'ENG',
                    JP: auth.serviceLang === 'JPN',
                    CN: auth.serviceLang === 'CHN',
                  }"
                ></dl>
                <!-- <dl class="banner12" :class="{ 'KR': auth.serviceLang === 'KOR', 'EN': auth.serviceLang === 'ENG', 'JP': auth.serviceLang === 'JPN', 'CN': auth.serviceLang === 'CHN'}" /> -->
              </div>
              <!-- slick_ban // -->
            </div>
            <!-- service //-->

            <div class="service">
              <!-- service -->
              <div class="slick_ban2">
                <!-- slick_ban2 -->
                <dl
                  @click="clickBanner('ongoing')"
                  class="banner21"
                  :class="{
                    KR: auth.serviceLang === 'KOR',
                    EN: auth.serviceLang === 'ENG',
                    JP: auth.serviceLang === 'JPN',
                    CN: auth.serviceLang === 'CHN',
                  }"
                />
              </div>
              <!-- slick_ban2 // -->
            </div>
            <!-- service //-->
            <div class="service">
              <!-- service -->
              <div class="slick_ban2">
                <!-- slick_ban2 -->
                <dl
                  @click="clickBanner('freight')"
                  class="banner22"
                  :class="{
                    KR: auth.serviceLang === 'KOR',
                    EN: auth.serviceLang === 'ENG',
                    JP: auth.serviceLang === 'JPN',
                    CN: auth.serviceLang === 'CHN',
                  }"
                />
              </div>
              <!-- slick_ban2 // -->
            </div>
            <!-- service //-->
          </div>
          <!-- banner area // -->
        </div>
        <!-- content_main // -->
      </div>
      <!--  ##### container_main //  ##### -->
    </div>
  </body>
</template>

<script>
import { rootComputed } from '@/store/helpers'

import EAutoCompletePlace from '@/components/common/EAutoCompletePlace'
import store from '@/store/index'
import schedule from '@/api/rest/schedule/schedule'
import notices from '@/api/services/notices'
import trans from '@/api/rest/trans/trans'
import moment from 'moment'

export default {
  name: 'Main',
  components: {
    EAutoCompletePlace,
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'fre-surcharge-pop': () => import('@/pages/schedule/popup/FreSurchargePop'),
    'fre-surcharge-pop2': () => import('@/pages/schedule/popup/FreSurchargePop2'),
    'request-quote-pop': () => import('@/pages/schedule/popup/RequestQuotePop'),
    'schedule-leg-pop': () => import('@/pages/schedule/popup/ScheduleLegPop'),
    'bl-do-payment-pop': () => import('@/pages/trans/popup/BlDoPaymentPop'),
    'container-tare-weight-pop': () => import('@/pages/trans/popup/ContainerTareWeightPop')
  },
  computed: {
    ...rootComputed
  },
  data () {
    return {
      customComponent: null,
      // 스케줄 검색
      scheduleForm: {
        startCtrCd: '',
        startPlcCd: '',
        startPolName: '',
        destCtrCd: '',
        destPlcCd: '',
        destPodName: ''
      },
      // 화물추적 검색
      cargoTrackingForm: {
        keyword: ''
      },
      // 운임문의 검색
      shippingCostForm: {
        // 출발 국가
        porCtrCd: '',
        // 출발 지역
        porPlcCd: '',
        // 출발지 명
        polNm: '',
        // 도착 국가
        dlyCtrCd: '',
        // 도착 지역
        dlyPlcCd: '',
        // 도착지 명
        podNm: '',
        // 예상 출항일
        etd: this.$ekmtcCommon.getDateToStrDelim(new Date()),
        // Freight Term(Prepaid(P)/Collect(C))
        frtTerm: 'P',
        // 수-출입 구분 코드
        eiCatCd: '',

        // 영업사원 정보
        salNm: '',
        salTelNo: '',
        salUno: '',
        salEmlAddr: ''
      },
      // KMTC SPOT 검색
      scheduleKmtcSpotForm: {
        startCtrCd: '',
        startPlcCd: '',
        startPolName: '',
        destCtrCd: '',
        destPlcCd: '',
        destPodName: ''
      },
      searchFlag: 'S',
      // 팝업을 띄우기 위해 필요한 데이터
      popupParams: {},

      quickMenu: {
        pickup: {
          openFlag: false,
          keyword: ''
        },
        demdet: {
          openFlag: false,
          keyword: ''
        },
        payment: {
          openFlag: false,
          keyword: ''
        },
        return: {
          openFlag: false,
          keyword: ''
        },
        cntrTareWeight: {
          openFlag: false,
          keyword: ''
        }
      },

      noticeList: [],
      bodyAddEventFlag: false,
      autoCompleteWidth: '301px',
      eiCatCdRadio: 'I',
      serviceCtrCd: '',
      year: '',
      month: '',
      relYn: true, // JamSin
      isShow: {
        text: true,
        link: true,
        close: true
      },
      kmtcSpotUserYn: 'N',
      kmtcSpotNegoYn: 'N', // D등급
      kmtcSpotCNegoYn: 'N', // C등급
      devYn: process.env.VUE_APP_MODE !== 'PRD'
    }
  },
  created () {
    if (this.serviceLang === 'KOR' || this.serviceLang === 'CHN') {
      this.autoCompleteWidth = '301px'
    } else if (this.serviceLang === 'JPN') {
      this.autoCompleteWidth = '286px'
    } else if (this.serviceLang === 'ENG') {
      this.autoCompleteWidth = '260px'
    }

    // 로그인 후 다시 메인으로 온 경우
    // 비로그인 상태에서 입력한 운임운의 탭(메인 가운데)에 입력한 출발/도착지를 들고와서 세팅 및 처리
    this.init()
    this.settingFreightInquiry()
    this.settingNotice()
    this.getPopupList()

    // 로그인한 경우 KMTC SPOT 대상 조회
    if (this.auth.login) {
      this.getKmtcSpotTarget()
    }
  },
  mounted () {
    $(function () {
      $('.slick_ban, .slick_ban2').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        dots: true,
        arrows: false
      })
    })

    this.$nextTick(function () {
      window.addEventListener('keydown', this.handleKeydown)
    })

    // 아래 로직은 한국 이외 가로배너 텍스트가 비노출 되므로 주의해서 사용해야 함
    // if (this.serviceLang !== 'KOR') {
    //   this.mainBannerClose()
    // }
  },
  destroyed () {
    window.removeEventListener('keydown', this.handleKeydown)
  },
  methods: {
    async init () {
      // 1. 로그인 시 로그인한 유저의 국가정보
      // 2. 브라우저 접속한 국가정보
      // 3. 상단 드롭다운의 언어정보 (Default: KOR)
      if (!this.auth.userId) {
        this.serviceCtrCd = this.$ekmtcCommon.isNotEmpty(
          localStorage.getItem('service_ctrcd')
        )
          ? localStorage.getItem('service_ctrcd')
          : localStorage.getItem('service_lang')
      } else {
        this.serviceCtrCd = this.memberDetail.userCtrCd
      }

      // KOR -> KR
      if (this.serviceCtrCd === 'KOR') {
        this.serviceCtrCd = 'KR'
      }
    },
    handleKeydown (e) {
      // JamSin
      if (this.relYn) {
        if (e.key === 'Enter') {
          if (this.quickMenu.pickup.openFlag) {
            this.searchByQuickMenu('pickup')
          } else if (this.quickMenu.demdet.openFlag) {
            this.searchByQuickMenu('demdet')
          } else if (this.quickMenu.payment.openFlag) {
            this.searchByQuickMenu('payment')
          } else if (this.quickMenu.return.openFlag) {
            this.searchByQuickMenu('return')
          } else if (this.searchFlag === 'S' && this.$ekmtcCommon.isNotEmpty(this.scheduleForm.destPlcCd) && this.$ekmtcCommon.isNotEmpty(this.scheduleForm.startPlcCd)) {
            this.goSchedule()
          } else if (this.searchFlag === 'C') {
            this.goCargoTracking()
          } else if (this.searchFlag === 'SC') {
            this.fnSearchSurCharge()
          } else if (this.searchFlag === 'KS') {
            this.goScheduleKmtcSpot('03')
          } else if (this.quickMenu.cntrTareWeight.openFlag) {
            this.searchByQuickMenu('cntrTareWeight')
          }
        }
      }
    },
    getKmtcSpotTarget () {
      //menuCD 01은 로그를 남기지 않음. kmtc on 대상 여부만 조회
      schedule.getKmtcSpotTarget('01').then((response) => {
        this.kmtcSpotUserYn = response.data[0].kmtcSpotUserYn
        this.kmtcSpotNegoYn = response.data[0].kmtcSpotNegoYn
        this.kmtcSpotCNegoYn = response.data[0].kmtcSpotCNegoYn
      })
    },
    async settingNotice () {
      const params = {
        tableId: 'A',
        cdId: 'WB003',
        mainCall: 'Y',
        serviceCtrCd: localStorage.getItem('service_ctrcd')
      }

      this.noticeList = []

      await notices
        .getNoticesList(params)
        .then((response) => {
          const resp = response.data
          const noticeList = resp.noticeList

          if (noticeList !== undefined && noticeList.length > 0) {
            for (let index = 0; index < noticeList.length; index++) {
              if (index === 4) break

              const notice = noticeList[index]

              if (this.$ekmtcCommon.isNotEmpty(notice.fstEntDtm)) {
                notice.fstEntDtm = this.$ekmtcCommon.changeDatePattern(
                  notice.fstEntDtm.substring(0, 8),
                  '-'
                )
              }

              this.noticeList.push(notice)
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async getPopupList () {
      if (!this.auth.userId) {
        const params = {
          serviceCtrCd: localStorage.getItem('service_ctrcd')
        }
        await notices.getPopupList(params).then((res) => {
          const popupList = res.data

          const cookie = document.cookie

          let left = 0
          for (const p of popupList) {
            if (cookie.length > 0) {
              if (cookie.indexOf('notice' + p.messageNum) > -1) {
                continue
              }
            }
            const ops = {
              url: '#/open-popup/notice-pop?messageNum=' + p.messageNum,
              target: 'noticePop',
              popOption:
                'width=' +
                p.widthSize +
                ', height=' +
                p.heightSize +
                ', left=' +
                left +
                ', menubar=no, location=no, status=no, scrollbars=yes, resizable=no'
            }

            window.open(ops.url, 'notice' + p.messageNum, ops.popOption)

            left += Number(p.widthSize)
          }
        })
      }
    },
    // [s] '스케줄' 출발/도착 change event ===================
    changePol (vo) {
      this.scheduleForm.startCtrCd = vo.ctrCd
      this.scheduleForm.startPlcCd = vo.plcCd
      this.scheduleForm.startPolName = vo.plcEnm

      const frm = document.querySelector('#frm_main')
      const elem = frm.querySelector('#polPol')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    changePod (vo) {
      this.scheduleForm.destCtrCd = vo.ctrCd
      this.scheduleForm.destPlcCd = vo.plcCd
      this.scheduleForm.destPodName = vo.plcEnm

      const frm = document.querySelector('#frm_main')
      const elem = frm.querySelector('#podPod')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    // [e] '스케줄' 출발/도착 change event ===================
    // [s] '운임문의' 출발/도착 change event ===================
    changePol2 (vo) {
      this.shippingCostForm.porCtrCd = vo.ctrCd
      this.shippingCostForm.porPlcCd = vo.plcCd
      this.shippingCostForm.polNm = vo.plcEnm
      this.fnSalUnoSet()

      const frm = document.querySelector('#frm_main')
      const elem = frm.querySelector('#polPol2')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    changePod2 (vo) {
      this.shippingCostForm.dlyCtrCd = vo.ctrCd
      this.shippingCostForm.dlyPlcCd = vo.plcCd
      this.shippingCostForm.podNm = vo.plcEnm
      this.fnSalUnoSet()

      const frm = document.querySelector('#frm_main')
      const elem = frm.querySelector('#podPod2')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    // KMTC SPOT 출발/도착 change event ===================
    changePol3 (vo) {
      this.scheduleKmtcSpotForm.startCtrCd = vo.ctrCd
      this.scheduleKmtcSpotForm.startPlcCd = vo.plcCd
      this.scheduleKmtcSpotForm.startPolName = vo.plcEnm

      const frm = document.querySelector('#frm_main')
      const elem = frm.querySelector('#polPol3')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    changePod3 (vo) {
      this.scheduleKmtcSpotForm.destCtrCd = vo.ctrCd
      this.scheduleKmtcSpotForm.destPlcCd = vo.plcCd
      this.scheduleKmtcSpotForm.destPodName = vo.plcEnm

      const frm = document.querySelector('#frm_main')
      const elem = frm.querySelector('#podPod3')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    // [e] '운임문의' 출발/도착 change event ===================
    async checkValidationPor (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_main')
      }

      let elem = ''

      if (this.searchFlag === 'S') {
        elem = frm.querySelector('#polPol input')
      }

      if (this.searchFlag === 'SC') {
        elem = frm.querySelector('#polPol2 input')
      }

      if (this.searchFlag === 'KS') {
        elem = frm.querySelector('#polPol3 input')
      }

      let isOk = true
      let msg = ''
      let porCtrCd = ''
      let porPlcCd = ''

      // '스케줄'인 경우
      if (this.searchFlag === 'S') {
        porCtrCd = this.scheduleForm.startCtrCd
        porPlcCd = this.scheduleForm.startPlcCd
      }
      // '운임'인 경우
      if (this.searchFlag === 'SC') {
        porCtrCd = this.shippingCostForm.porCtrCd
        porPlcCd = this.shippingCostForm.porPlcCd
      }
      // KMTC SPOT인 경우
      if (this.searchFlag === 'KS') {
        porCtrCd = this.scheduleKmtcSpotForm.startCtrCd
        porPlcCd = this.scheduleKmtcSpotForm.startPlcCd
      }

      if (
        this.$ekmtcCommon.isEmpty(porCtrCd) ||
        this.$ekmtcCommon.isEmpty(porPlcCd)
      ) {
        msg = this.$t('msg.MAIN010G030.030') // '출발지를 입력하세요.'
        isOk = false
        if (await this.$ekmtcCommon.asyncAlertMessage({ message: msg })) {
          elem.focus()
        }
      }

      return isOk
    },
    async checkValidationPod (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_main')
      }

      let elem = ''

      if (this.searchFlag === 'S') {
        // elem = frm.querySelector('#podPod')
        elem = frm.querySelector('#podPod input')
      }

      if (this.searchFlag === 'SC') {
        // elem = frm.querySelector('#podPod2')
        elem = frm.querySelector('#podPod2 input')
      }

      if (this.searchFlag === 'KS') {
        // elem = frm.querySelector('#podPod2')
        elem = frm.querySelector('#podPod3 input')
      }

      let isOk = true
      let msg = ''
      let podCtrCd = ''
      let podPlcCd = ''

      // '스케줄'인 경우
      if (this.searchFlag === 'S') {
        podCtrCd = this.scheduleForm.destCtrCd
        podPlcCd = this.scheduleForm.destPlcCd
      }
      // '운임'인 경우
      if (this.searchFlag === 'SC') {
        podCtrCd = this.shippingCostForm.dlyCtrCd
        podPlcCd = this.shippingCostForm.dlyPlcCd
      }
      // KMTC SPOT인 경우
      if (this.searchFlag === 'KS') {
        podCtrCd = this.scheduleKmtcSpotForm.destCtrCd
        podPlcCd = this.scheduleKmtcSpotForm.destPlcCd
      }
      if (
        this.$ekmtcCommon.isEmpty(podCtrCd) ||
        this.$ekmtcCommon.isEmpty(podPlcCd)
      ) {
        msg = this.$t('msg.MAIN010G030.031') // '도착지를 입력하세요.'
        isOk = false
        if (await this.$ekmtcCommon.asyncAlertMessage({ message: msg })) {
          elem.focus()
        }
      }

      return isOk
    },
    async fnValidationPorPod () {
      const frm = document.querySelector('#frm_main')
      let isAllOk = true

      // 출발지 validation check
      if (!(await this.checkValidationPor(frm))) {
        isAllOk = false
      } else {
        // 도착지 validation check
        if (!(await this.checkValidationPod(frm))) {
          isAllOk = false
        }
      }

      return isAllOk
    },
    async goScheduleKmtcSpot (menuCd) {
      // 검색조건 전체 validation check
      //if (await this.fnValidationPorPod()) { // 출발, 도착 없이 이동을 위해 주석 처리함
        const date = new Date()
        this.year = date.getFullYear()
        this.month = date.getMonth() + 1

        if (this.month < 10) {
          this.month = '0' + this.month
        }

        const params = {
          porCtrCd: this.scheduleKmtcSpotForm.startCtrCd,
          porPlcCd: this.scheduleKmtcSpotForm.startPlcCd,
          dlyCtrCd: this.scheduleKmtcSpotForm.destCtrCd,
          dlyPlcCd: this.scheduleKmtcSpotForm.destPlcCd,
          yyyymm: this.year + '' + this.month,
          menuCd: menuCd
        }
        this.$router.push({ name: 'kmtc-premium', query: params }).catch(() => {})
     // }
    },
    async goSchedule () {
      // 검색조건 전체 validation check
      if (await this.fnValidationPorPod()) {
      /* 스케쥴 조회시 팝업창 연결용
        this.popupParams = {
          ScheduleLegPop: 'M',
          podCtrCd: this.scheduleForm.destCtrCd,
          podPlcCd: this.scheduleForm.destPlcCd,
          polCtrCd: this.scheduleForm.startCtrCd,
          polPlcCd: this.scheduleForm.startPlcCd,
          rfYn: 'N'
        }

        this.openPopup('schedule-leg-pop')
      */
        const date = new Date()
        this.year = date.getFullYear()
        this.month = date.getMonth() + 1

        if (this.month < 10) {
          this.month = '0' + this.month
        }

        const params = {
          porCtrCd: this.scheduleForm.startCtrCd,
          porPlcCd: this.scheduleForm.startPlcCd,
          dlyCtrCd: this.scheduleForm.destCtrCd,
          dlyPlcCd: this.scheduleForm.destPlcCd,
          yyyymm: this.year + '' + this.month,
          loginChk: ''// 로그인 없이 스케쥴 메뉴 진입하도록 예외 처리함
        }
        this.$router.push({ name: 'leg', query: params }).catch(() => {})
      }
    },
    async chgSearchMenu (flag) {
      if (flag === null) return

      if (flag === 'S') {
        // console.log('##### schedule #####')
        this.searchFlag = 'S'

        this.cargoTrackingForm.keyword = ''

        this.shippingCostForm.porCtrCd = ''
        this.shippingCostForm.porPlcCd = ''
        this.shippingCostForm.polNm = ''
        this.shippingCostForm.dlyCtrCd = ''
        this.shippingCostForm.dlyPlcCd = ''
        this.shippingCostForm.podNm = ''
      } else if (flag === 'C') {
        // console.log('##### cargo #####')
        this.searchFlag = 'C'

        this.scheduleForm.startCtrCd = ''
        this.scheduleForm.startPlcCd = ''
        this.scheduleForm.startPolName = ''
        this.scheduleForm.destCtrCd = ''
        this.scheduleForm.destPlcCd = ''
        this.scheduleForm.destPodName = ''

        this.shippingCostForm.porCtrCd = ''
        this.shippingCostForm.porPlcCd = ''
        this.shippingCostForm.polNm = ''
        this.shippingCostForm.dlyCtrCd = ''
        this.shippingCostForm.dlyPlcCd = ''
        this.shippingCostForm.podNm = ''
      } else if (flag === 'SC') {
        //운임인 경우
        // console.log('##### shipping cost #####')
        this.searchFlag = 'SC'

        this.cargoTrackingForm.keyword = ''

        this.scheduleForm.startCtrCd = ''
        this.scheduleForm.startPlcCd = ''
        this.scheduleForm.startPolName = ''
        this.scheduleForm.destCtrCd = ''
        this.scheduleForm.destPlcCd = ''
        this.scheduleForm.destPodName = ''
      } else if (flag === 'KS') {
        // KMTC SPOT 경우
        this.searchFlag = 'KS'

        this.scheduleKmtcSpotForm.startCtrCd = ''
        this.scheduleKmtcSpotForm.startPlcCd = ''
        this.scheduleKmtcSpotForm.startPolName = ''
        this.scheduleKmtcSpotForm.destCtrCd = ''
        this.scheduleKmtcSpotForm.destPlcCd = ''
        this.scheduleKmtcSpotForm.destPodName = ''
      }
    },
    // 영업사원 설정
    async fnSalUnoSet () {
      if (!this.auth.userId) {
        return
      }

      const porCd = this.shippingCostForm.porCtrCd
      const dlyCd = this.shippingCostForm.dlyCtrCd

      if (
        this.$ekmtcCommon.isEmpty(porCd) ||
        this.$ekmtcCommon.isEmpty(dlyCd)
      ) {
        return
      }

      const tmpParam = {
        porCd: porCd,
        dlyCd: dlyCd
      }

      await schedule.findSalUno(tmpParam).then((response) => {
        if (response.status === 200) {
          const respData = response.data

          if (respData.eiCatCd !== undefined) {
            this.shippingCostForm.eiCatCd = respData.eiCatCd

            const eiCatCd = this.shippingCostForm.eiCatCd
            if (eiCatCd === 'O' || eiCatCd === 'OJ' || eiCatCd === 'OJRF') {
              this.shippingCostForm.frtTerm = 'P'
            } else if (eiCatCd === 'I') {
              this.shippingCostForm.frtTerm = 'C'
            }
          }
          if (respData.salNm !== undefined) {
            this.shippingCostForm.salNm = respData.salNm
          }
          if (respData.salTelNo !== undefined) {
            this.shippingCostForm.salTelNo = respData.salTelNo
          }
          if (respData.salUno !== undefined) {
            this.shippingCostForm.salUno = respData.salUno
          }
          if (respData.salEmlAddr !== undefined) {
            this.shippingCostForm.salEmlAddr = respData.salEmlAddr
          }
        }
      })
    },
    async getCntrTypeMessage () {
      let message = this.$t('msg.CSCT060G040.010') + '<br><br>'

      message += '<div class="d_flex">'
      message += '  <div class="inp_space30">Type : </div>'
      message += '  <div class="inp_space70">'
      message += '  <select id="popCntrTypCd">'
      message +=
        '  <option value="">' + this.$t('msg.CSBL200.075') + '</option>'
      message += '  <option value="GP">Dry</option>'
      message += '  <option value="RF">Reefer</option>'
      message += '  <option value="FR">Flat Rack</option>'
      message += '  <option value="OT">Open Top</option>'
      message += '  </select>'
      message += '  </div>'
      message += '</div>'

      let rtnVal = ''
      const isOk = await this.$ekmtcCommon.asyncAlertMessage({
        message: message,
        useConfirmBtn: true,
        onConfirmSelf: (resolve, $Simplert) => {
          const popCntrTypCd = document.querySelector('#popCntrTypCd')

          if (this.$ekmtcCommon.isEmpty(popCntrTypCd.value)) {
            this.$ekmtcCommon.showErrorTooltip(
              popCntrTypCd,
              this.$t('msg.CSBK100.147')
            )
          } else {
            rtnVal = popCntrTypCd.value
            resolve(true)
            $Simplert.close()
          }
        }
      })

      return {
        isOk: isOk,
        rtnVal: rtnVal
      }
    },
    async fnFrtConfirm () {
      const shippingCostForm = this.shippingCostForm
      const params = {
        porCtrCd: shippingCostForm.porCtrCd,
        porPlcCd: shippingCostForm.porPlcCd,
        dlyCtrCd: shippingCostForm.dlyCtrCd,
        dlyPlcCd: shippingCostForm.dlyPlcCd,
        etd: shippingCostForm.etd,
        eiCatCd: shippingCostForm.eiCatCd
      }

      const cntrMsgInfo = await this.getCntrTypeMessage()

      if (cntrMsgInfo.isOk) {
        params.cntrTypCd = cntrMsgInfo.rtnVal

        await schedule.fnPopFreReq(params).then((response) => {
          const result = response.data.insertRateMngResult
          let msg = ''
          if (result !== undefined && result === 1) {
            //msg = this.$t('msg.FARE010T010.022') // 문의하신 구간에 대한 운임등록 요청이 접수되었습니다. 잠시 후에 다시 확인하시기 바랍니다.
            // msg = this.$t('msg.FARE010T010.025') // 현재 요청하신 기간 및 구간에 등록된 운임이 없습니다.
            const confirmOpt = {
              alertType: 'simple',
              customCloseBtnText: this.$t('msg.VOSD100_M5.004'), // 닫기
              customConfirmBtnText: this.$t('msg.VOSD100_M5.003'), // 확인
              useConfirmBtn: true,
              message: `${this.$t('msg.FARE010T010.025')}\n${this.$t('msg.FARE010T010.026')}`, // 현재 요청하신 기간 및 구간에 등록된 운임이 없습니다.\n영업 사원에게 문의 하시겠습니까?
              onConfirm: this.sendSalEmail
            }

            this.$ekmtcCommon.alert(confirmOpt)
          } else if (result !== undefined && result === 2) { // 등록된 운임이 있음
            msg = this.$t('msg.FARE010T010.024')
            this.$ekmtcCommon.alertDefault(msg)
          } else if (result !== undefined && result === 3) { // 요청중인 내역이 있는경우 메시지만 동일하게 출력
            //msg = this.$t('msg.FARE010T010.022') // 문의하신 구간에 대한 운임등록 요청이 접수되었습니다. 잠시 후에 다시 확인하시기 바랍니다.
            // msg = this.$t('msg.FARE010T010.025') // 현재 요청하신 기간 및 구간에 등록된 운임이 없습니다.
            const confirmOpt = {
              alertType: 'simple',
              customCloseBtnText: this.$t('msg.VOSD100_M5.004'), // 닫기
              customConfirmBtnText: this.$t('msg.VOSD100_M5.003'), // 확인
              useConfirmBtn: true,
              message: `${this.$t('msg.FARE010T010.025')}\n${this.$t('msg.FARE010T010.026')}`, // 현재 요청하신 기간 및 구간에 등록된 운임이 없습니다.\n영업 사원에게 문의 하시겠습니까?
              onConfirm: this.sendSalEmail
            }

            this.$ekmtcCommon.alert(confirmOpt)
          } else {
            msg = this.$t('msg.FARE010T010.023') // 처리 중 오류가 발생하였습니다. 시스템 관리자에게 문의해 주세요.
            this.$ekmtcCommon.alertDefault(msg)
          }
        })
      }
    },
    // 운임 관련 담당 영업사원 이메일 전송
    async sendSalEmail () {
      const param = {
        polCtrCd: this.shippingCostForm.porCtrCd,
        podCtrCd: this.shippingCostForm.dlyCtrCd,
        polPortCd: this.shippingCostForm.porPlcCd,
        podPortCd: this.shippingCostForm.dlyPlcCd,
        salEmlAddr: this.shippingCostForm.salEmlAddr
      }

      schedule.sendSalEmail(param)
        .then(async (response) => {
          // console.log('sendSalEmail response @@@@@ ', response)

          if (response.headers.respmsg === 'SUCC') {
            this.$ekmtcCommon.alertDefault(this.$t('art.CMATK146')) // 메일이 전송되었습니다.
          } else {
            this.$ekmtcCommon.alertDefault(this.$t('mgs.SETT060G010.049')) // 전송에 실패했습니다.
          }
        })
        .catch(err => {
          console.log(err)
          this.$ekmtcCommon.alertDefault(this.$t('mgs.SETT060G010.049')) // 전송에 실패했습니다.
        })
    },
    // 운임 검색 ('확인'버튼 이벤트)
    async fnSearchSurCharge () {
      // 검색조건 전체 validation check
      if (await this.fnValidationPorPod()) {
        let authOk = true

        if (!this.auth.userId) {
          authOk = false

          // 로그인이 필요한 메뉴입니다.
          if (
            await this.$ekmtcCommon.asyncAlertMessage({
              message: this.$t('msg.MAIN010G030.034')
            })
          ) {
            if (!this.$store.getters['auth/requireLogin']) {
              this.$store.commit(
                'auth/requireLogin',
                !this.$store.getters['auth/requireLogin']
              )

              const freightSearchInfo = {
                porCtrCd: this.shippingCostForm.porCtrCd,
                porPlcCd: this.shippingCostForm.porPlcCd,
                polNm: this.shippingCostForm.polNm,
                dlyCtrCd: this.shippingCostForm.dlyCtrCd,
                dlyPlcCd: this.shippingCostForm.dlyPlcCd,
                podNm: this.shippingCostForm.podNm
              }

              sessionStorage.setItem(
                'freight-search-info',
                JSON.stringify(freightSearchInfo)
              )

              return
            }
          }
        }

        if (authOk) {
          // R/A No 조회
          await schedule.popFreAppNo(this.shippingCostForm).then(async (response) => {
              if (response.status === 200) {
                const respData = response.data

                const frtResult = this.$ekmtcCommon.isEmpty(respData.frtResult) ? '' : respData.frtResult
                const frtAppNo = this.$ekmtcCommon.isEmpty(respData.frtAppNo) ? '' : respData.frtAppNo
                const reqRno = this.$ekmtcCommon.isEmpty(respData.reqRno) ? '' : respData.reqRno
                const strDt = this.$ekmtcCommon.isEmpty(respData.strDt) ? '' : respData.strDt
                const endDt = this.$ekmtcCommon.isEmpty(respData.endDt) ? '' : respData.endDt

                // 메인에서 운임확인 팝업창에 전달할 파라미터 세팅
                this.popupParams = {
                  frtResult: frtResult,
                  frtAppNo: frtAppNo,
                  reqRno: reqRno,
                  startDt: strDt,
                  endDt: endDt,
                  kmtcSpotUserYn: this.kmtcSpotUserYn
                }

                const oonlYn = this.memberDetail.oonlYn // 수출영업(Export) 기준으로 온라인 견적 대상 화주인 경우
                const jonlYn = this.memberDetail.jonlYn // 일본영업 기준으로 온라인 견적 대상 화주인 경우
                const ionlYn = this.memberDetail.ionlYn // 수입영업(Import) 기준으로 온라인 견적 대상 화주인 경우
                const oengOnlYn = this.memberDetail.oengOnlYn
                const userCtrCd = this.memberDetail.userCtrCd

                let rmPopOpenFlag = false

                // ****************************** [s] 온라인 견적 적용 조건 ******************************
                if (oonlYn === 'Y' && jonlYn === 'Y') {
                  // 1) 수출영업(Export) 과 일본영업에 대해서 모두 온라인 견적 대상 화주인 경우
                  if (this.shippingCostForm.porCtrCd === 'KR') {
                    // POL (출발항) 에 KR 소재의 port 를 입력하면
                    // 온라인 견적이 적용
                    rmPopOpenFlag = true
                  } else if (this.shippingCostForm.porCtrCd === 'KR' && this.shippingCostForm.dlyCtrCd === 'JP') {
                      rmPopOpenFlag = true
                  } else if (this.shippingCostForm.porCtrCd === 'JP' && this.shippingCostForm.dlyCtrCd === 'KR') {
                      rmPopOpenFlag = true
                  }
                } else {
                  if (oonlYn === 'Y') {
                    // 2) 수출영업(Export) 기준으로 온라인 견적 대상 화주인 경우
                    if (this.shippingCostForm.porCtrCd === 'KR' && this.shippingCostForm.dlyCtrCd !== 'JP') {
                      // POL (출발항) 에 KR 소재의 port 를 입력하고
                      // POD (도착항) 에 JP (일본) 를 '제외한' 국가의 port 를 입력하는 경우에만
                      // 온라인 견적이 적용
                      rmPopOpenFlag = true
                    }
                  } else if (jonlYn === 'Y') {
                    // 3) 일본영업 기준으로 온라인 견적 대상 화주인 경우
                    if (this.shippingCostForm.porCtrCd === 'KR' && this.shippingCostForm.dlyCtrCd === 'JP') {
                      // 3-1)
                      // POL (출발항) 에 KR 소재의 port 를 입력하고
                      // POD (도착항) 에 JP (일본)의 port 를 입력하는 경우에만
                      // 온라인 견적이 적용
                      rmPopOpenFlag = true
                    }
                    if (this.shippingCostForm.porCtrCd === 'JP' && this.shippingCostForm.dlyCtrCd === 'KR') {
                      // 3-2)
                      // POL (출발항) 에 JP (일본) 의 port 를입력하고
                      // POD (도착항) 에 KR 소재의 port 를 입력하는 경우
                      // 온라인 견적이 적용
                      rmPopOpenFlag = true
                    }
                  }
                }

                if (ionlYn === 'Y') {
                  if (this.shippingCostForm.porCtrCd !== 'JP' && this.shippingCostForm.dlyCtrCd === 'KR') {
                    rmPopOpenFlag = true
                  }
                }

                if (oengOnlYn === 'Y' && userCtrCd !== 'KR') {
                  rmPopOpenFlag = true
                }
                // ****************************** [e] 온라인 견적 적용 조건 ******************************

                if (frtResult === 'Y') {
                  // ===========================================
                  // [1] R/A No(Rate Application) 정보가 있는 경우
                  // ===========================================
                  const rmOpenFlag = rmPopOpenFlag ? 'Y' : 'N'
                  this.popupParams = {
                    ...this.popupParams,
                    ...this.shippingCostForm,
                    ...{ rmPopOpenFlag: rmOpenFlag }
                  }

                  // 운임확인 팝업
                  this.openPopup('fre-surcharge-pop')
                } else if (frtResult === 'A') {
                  // =========================================================================
                  // [2] R/A(Rate Application) 정보가 없고, R/M(Rate Management) 정보가 있는 경우
                  // =========================================================================
                  if (rmPopOpenFlag) {
                    // 온라인 견적이 적용된 경우
                    this.popupParams = {
                      ...this.popupParams,
                      ...this.shippingCostForm
                    }
                    this.openPopup('fre-surcharge-pop2')
                  } else {
                    // 온라인 견적이 적용되지 않은 경우 - 일반화주
                    // "문의하신 구간에 등록된 운임이 없습니다. 견적 문의를 요청하시겠습니까?" 팝업 띄움
                    // 확인 - 운임문의 팝업(SCHD010P080)
                    // 취소 - Alert 닫기

                    // JamSin >> SR 1174683 진행중...
                    if (await this.getScheduleCountCheck() < 1) {
                      this.$ekmtcCommon.alertDefault(this.$t('msg.FARE010T010.027'))
                      return
                    }

                    const TH = this
                    // 문의하신 구간에 등록된 운임이 없습니다. 견적 문의를 요청하시겠습니까?
                    if (await TH.$ekmtcCommon.asyncAlertMessage({ message: TH.$t('msg.FARE010T010.021'), useConfirmBtn: true })) {
                      TH.popupParams = TH.shippingCostForm
                      TH.openPopup('request-quote-pop')
                    }
                  }
                } else if (frtResult === 'N') {
                  // =================================================================
                  // [3] R/A(Rate Application), R/M(Rate Management) 정보 모두 없는 경우
                  // =================================================================

                  if (rmPopOpenFlag) {
                    // JamSin >> SR 1174683 진행중...
                    if (await this.getScheduleCountCheck() < 1) {
                      this.$ekmtcCommon.alertDefault(this.$t('msg.FARE010T010.027'))
                      return
                    }
                    // 온라인 견적이 적용된 경우
                    this.fnFrtConfirm()
                  } else {
                    // 온라인 견적이 적용되지 않은 경우 - 일반화주
                    // "문의하신 구간에 등록된 운임이 없습니다. 견적 문의를 요청하시겠습니까?" 팝업 띄움
                    // 확인 - 운임문의 팝업(SCHD010P080)
                    // 취소 - Alert 닫기

                    // JamSin >> SR 1174683 진행중...
                    if (await this.getScheduleCountCheck() < 1) {
                      this.$ekmtcCommon.alertDefault(this.$t('msg.FARE010T010.027'))
                      return
                    }

                    const TH = this
                    // 문의하신 구간에 등록된 운임이 없습니다. 견적 문의를 요청하시겠습니까?
                    if (await TH.$ekmtcCommon.asyncAlertMessage({ message: TH.$t('msg.FARE010T010.021'), useConfirmBtn: true })) {
                      TH.popupParams = TH.shippingCostForm
                      TH.openPopup('request-quote-pop')
                    }
                  }
                }
              }
            })
        }
      }
    },
    openPopup (compNm) {
      if (this.customComponent !== null) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.main_inquiry_pop')
      }
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('.main_inquiry_pop')
    },
    closePopup (obj) {
      if (obj && obj.negoYN) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.main_inquiry_pop')

        this.customComponent = 'request-quote-pop'
        this.$ekmtcCommon.layerOpen('.main_inquiry_pop')
      } else {
        if (this.customComponent === 'bl-do-payment-pop') {
          $('.quick_section,.main_inquiry_pop').removeAttr('style')
        }

        this.customComponent = null
        this.$ekmtcCommon.layerClose('.main_inquiry_pop')
      }
    },
    quickMenuClose (e) {
      const $el = $(e.target)

      if (
        $el.hasClass('quick_section') ||
        $el.parents('.quick_section').length > 0
      ) {
        return
      }

      this.quickMenuOpen('body')
    },
    quickMenuOpen (flag, e) {
      if (e !== undefined) {
        const $el = $(e.target)

        if ($el.hasClass('simplert') || $el.parents('.simplert').length > 0) {
          return
        }
      }

      const keys = Object.keys(this.quickMenu)

      keys.forEach((key) => {
        if (key === flag) {
          if (this.quickMenu[key].openFlag) {
            this.quickMenu[key].keyword = ''
          }
          this.quickMenu[key].openFlag = !this.quickMenu[key].openFlag
        } else {
          this.quickMenu[key].openFlag = false
          this.quickMenu[key].keyword = ''
        }
      })

      if (flag === 'body' && this.bodyAddEventFlag) {
        this.bodyAddEventFlag = false
        this.$el.removeEventListener('click', this.stopProp)
        document.body.removeEventListener('click', this.fnQuickMenuHide)
      }

      if (flag !== 'body' && !this.bodyAddEventFlag) {
        this.bodyAddEventFlag = true
        this.$el.addEventListener('click', this.stopProp)
        document.body.addEventListener('click', this.fnQuickMenuHide)
      }

      if (flag === 'lCharge') {
        this.$router.push('/common/info/local-charge').catch(() => {})
      }
    },
    async fnValidationQuickMenuKeyword (flag, frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_quick')
      }

      const elem = frm.querySelector(`#${flag}_keyword`)
      const keyword = this.quickMenu[flag].keyword
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isEmpty(keyword)) {
        msg = this.$t('msg.MAIN010G030.032') // '검색어를 입력해주세요.'
        isOk = false
        if (await this.$ekmtcCommon.asyncAlertMessage({ message: msg })) {
          elem.focus()
        }
      }

      return isOk
    },
    async searchByQuickMenu (flag) {
      if (await this.fnValidationQuickMenuKeyword(flag)) {
        this.quickMenu[flag].keyword = this.quickMenu[flag].keyword.replace(
          / /g,
          ''
        )
        let query = {
          keyword: this.quickMenu[flag].keyword
        }

        if (flag === 'pickup') {
          // this.$router.push({ name: 'pickup' })
          this.$router.push({ name: 'pickup-detail', query: query })
        }
        if (flag === 'payment') {
          this.popupParams = {
            blNo: this.quickMenu[flag].keyword
          }

          // D/O 발행팝업 열때, 퀵검색메뉴 닫기
          this.quickMenuOpen('body')

          this.openPopup('bl-do-payment-pop')
        }
        if (flag === 'return') {
          query.searchType = 'BL'

          const encodeSearchKeyword = encodeURIComponent(
            this.quickMenu[flag].keyword
          )
          const params = {
            keyword: encodeSearchKeyword,
            exceptLoginFlag: 'Y'
          }

          await trans
            .integratedSearch(params)
            .then((response) => {
              const result = response.data.noSearchResult
              if (result !== undefined) {
                const searchType = result.searchType
                const searchCode = result.searchCode
                const eiCatCd = result.eiCatCd

                if (flag === 'return') {
                  if (searchType === 'CONTAINER' && this.$ekmtcCommon.isNotEmpty(result.subCd)) {
                    query = {
                      searchType: 'BL',
                      keyword: result.subCd,
                      choiceCntrNo: searchCode
                    }
                  } else {
                    query = {
                      searchType: searchType === 'BOOKING' ? 'BL' : searchType,
                      keyword: searchCode
                    }
                  }
                }
              }

              if (flag === 'return') {
                this.$router.push({ name: 'container-return', query: query })
              }
            })
            .catch((error) => {
              console.log(error)
              // 처리 중 오류가 발생했습니다. 관리자에게 문의해주세요.
              this.$ekmtcCommon.alertDefault(this.$t('msg.MAIN010G030.033'))
            })
        }

        if (flag === 'cntrTareWeight') {
          this.popupParams = {
            keyword: this.quickMenu[flag].keyword
          }

          this.openPopup('container-tare-weight-pop')
        }

        if (flag === 'demdet') {
          let params = {
            eiCatCd: this.eiCatCdRadio,
            dtKnd: this.quickMenu[flag].keyword.length === 11 ? 'CN' : 'BL',
            num: this.quickMenu[flag].keyword.replace('KMTC', ''), // KMTC 제거
            blNo: '',
            cntrNo: ''
          }
          this.$store.commit('settle/updateFreetimeParams', params)
          setTimeout(() => {
            this.$router.push('/calcIssue/freetime-request').catch(() => {})
          }, 300)
        }
      }
    },
    async goCargoTracking () {
      if (await this.fnValidationKeyword()) {
        let query = {
          searchType: 'BL',
          keyword: this.cargoTrackingForm.keyword
        }

        const encodeSearchKeyword = encodeURIComponent(
          this.cargoTrackingForm.keyword
        )
        const params = {
          keyword: encodeSearchKeyword,
          exceptLoginFlag: 'Y'
        }

        await trans
          .integratedSearch(params)
          .then((response) => {
            const result = response.data.noSearchResult
            if (result !== undefined) {
              const searchType = result.searchType
              const searchCode = result.searchCode

              query = {
                searchType: searchType,
                keyword: searchCode
              }
            }

            this.$router.push({ name: 'cargo-tracking', query: query })
          })
          .catch((error) => {
            console.log(error)
            // 처리 중 오류가 발생했습니다. 관리자에게 문의해주세요.
            this.$ekmtcCommon.alertDefault(this.$t('msg.MAIN010G030.033'))
          })
      }
    },
    async fnValidationKeyword (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_main')
      }

      const elem = frm.querySelector('#cargoKeyword')
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isEmpty(this.cargoTrackingForm.keyword)) {
        msg = this.$t('msg.MAIN010G030.032') // '검색어를 입력해주세요.'
        isOk = false
        if (await this.$ekmtcCommon.asyncAlertMessage({ message: msg })) {
          elem.focus()
        }
      }

      return isOk
    },
    fnFocusInput (id) {
      document.querySelector(`#${id} input`).focus()
    },
    // 로그인 후 다시 메인으로 온 경우
    // 비로그인 상태에서 입력한 운임운의 탭(메인 가운데)에 입력한 출발/도착지를 들고와서 세팅 및 처리
    async settingFreightInquiry () {
      const tempFreightSearchInfo = sessionStorage.getItem(
        'freight-search-info'
      )
      const freightSearchInfo =
        tempFreightSearchInfo === null || tempFreightSearchInfo === undefined
          ? undefined
          : JSON.parse(tempFreightSearchInfo)

      if (freightSearchInfo !== undefined) {
        this.shippingCostForm = {
          ...this.shippingCostForm,
          ...freightSearchInfo
        }
        sessionStorage.removeItem('freight-search-info')

        this.chgSearchMenu('SC')

        await this.fnSalUnoSet()
      }
    },
    fnQuickMenuHide (e) {
      this.quickMenuOpen('body', e)
    },
    stopProp (e) {
      e.stopPropagation()
    },
    clickBanner (type) {
      console.log('@@@@ enter clickBanner: ', type)
      if (type === 'mobile') {
        let _noticeId = ''
        console.log('goManual')
        if (this.auth.login) {
          switch (this.auth.userCtrCd) {
            case 'KR':
              _noticeId = '1497'
              break
            case 'AE':
              _noticeId = '1506'
              break
            case 'CN':
              _noticeId = '1507'
              break
            case 'HK':
              _noticeId = '1508'
              break
            case 'ID':
              _noticeId = '1510'
              break
            case 'IN':
              _noticeId = '1511'
              break
            case 'JP':
              _noticeId = '1504'
              break
            case 'MY':
              _noticeId = '1512'
              break
            case 'PH':
              _noticeId = '1513'
              break
            case 'SG':
              _noticeId = '1516'
              break
            case 'TH':
              _noticeId = '1518'
              break
            case 'VN':
              _noticeId = '1519'
              break
            case 'RU':
              _noticeId = '1520'
              break
            case 'TW':
              _noticeId = '1517'
              break
            case 'KH':
              _noticeId = '1521'
              break
            case 'MM':
              _noticeId = '1522'
              break
            case 'LK':
              _noticeId = '1525'
              break
            case 'PK':
              _noticeId = '1506'
              break
          }

          this.$router.push({
            name: 'notice',
            query: {
              tableId: 'A',
              noticeId: _noticeId
            }
          })
        } else {
          //
          switch (this.serviceLang) {
            case 'KOR':
              _noticeId = '1497'
              break
            case 'ENG':
              _noticeId = '1506'
              break
            case 'JPN':
              _noticeId = '1504'
              break
            case 'CHN':
              _noticeId = '1507'
              break
          }

          this.$router.push({
            name: 'notice',
            query: {
              tableId: 'A',
              noticeId: _noticeId
            }
          })
        }
      }
      if (type === 'ongoing') {
        //
        this.$router.push('/working/progress')
      }
      if (type === 'freight') {
        this.$router.push('/shipping-cost/inquiry')
      }
      if (type === 'report') {
        this.$router.push({ name: 'report-list', params: { menuCd: '01' } }).catch(() => {})
      }
      // KMTC ON D 등급 화주는 로그 기록 후 KMTC ON NOW 화면으로 이동
      if (type === 'kmtcon') {
        this.goScheduleKmtcSpot('02')
      }
      // KMTC ON C 등급 화주는 스케줄 화면으로 이동
      if (type === 'kmtconC') {
        this.$router.push('/schedule/leg')
      }
    },
/* 추후 배너 오픈시 사용
    mainBannerOpen: function () {
      this.$el.addEventListener('click', this.stopProp)
      document.body.addEventListener('click', this.mainBannerClose)

      $('.main_banner').animate({ right: 0 })
      this.getIsShow()
    },
*/
    mainBannerClose: function () {
      this.isShow.text = false
      this.isShow.link = false
      this.isShow.close = false

      this.$el.removeEventListener('click', this.stopProp)
      document.body.removeEventListener('click', this.mainBannerClose)

      $('.main_banner').animate({ height: -50 })
    },
    fngoSurvey () {
      window.open('https://url.kr/14kz9l', 'win')
    },
    async getScheduleCountCheck () {
      let strDate = moment().format('YYYYMMDD')
      let srchYear = moment().format('YYYY')
      let srchMonth = moment().format('MM')
      let endDate = moment().add(1, 'M').format('YYYYMMDD')
      let scheduleSize = 0
      let param = {
        mobileYN: 'Y',
        startCtrCd: this.shippingCostForm.porCtrCd,
        startPlcCd: this.shippingCostForm.porPlcCd,
        destCtrCd: this.shippingCostForm.dlyCtrCd,
        destPlcCd: this.shippingCostForm.dlyPlcCd,
        searchYear: srchYear,
        searchMonth: srchMonth,
        srchDt: strDate,
        startDate: strDate,
        endDate: endDate,
        totStartDate: strDate,
        totEndDate: endDate,
        bound: 'O',
        calendarOrList: 'L',
        vslType01: '01',
        vslType03: '03',
        filterDirect: 'Y',
        filterTs: 'Y',
        eiCatCd: 'O',
        searchYN: 'Y',
        filterTranMax: '0',
        filterTranMin: '0',
        main: 'N',
        legIdx: '0',
        cpYn: 'N'
      }
      await schedule.getLegSchedule(param).then((rtnData) => {
        console.log('$$$$$$$$ getLegSchedule $$$$$$$$')
        //console.log(rtnData)
        scheduleSize = rtnData.data.listSchedule.length
      })

      return scheduleSize
    }
  }
}
</script>

<style lang="scss" scoped>
.notice_area dt {
  height: 87px;
}
.notice_area dt > .text_overflow {
  height: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.notice_area dd {
  height: 130px;
}
.notice_area dd > .text_overflow {
  height: 84px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.wrap_ENG .main_cont .sch_form .tit {
  width: 100px !important;
}
.wrap_CHN .main_cont .sch_form .tit,
.wrap_JPN .main_cont .sch_form .tit {
  width: 67px !important;
}
.ei-cat-cd-radio {
  margin: 0px 9px;
  font-size: 13px;
}
.banner11 {
  background-size: 100% 100% !important;
  &.KR {
    background: url(../assets/images/main/banner_mobile_KR.png) no-repeat 0 0;
  }
  &.EN {
    background: url(../assets/images/main/banner_mobile_EN.png) no-repeat 0 0;
  }
  &.JP {
    background: url(../assets/images/main/banner_mobile_JP.png) no-repeat 0 0;
  }
  &.CN {
    background: url(../assets/images/main/banner_mobile_CN.png) no-repeat 0 0;
  }
}
.banner12 {
  background-size: 100% 100% !important;
  &.KR {
    background: url(../assets/images/main/banner_ongoing_KR.png) no-repeat 0 0;
  }
  &.EN {
    background: url(../assets/images/main/banner_ongoing_EN.png) no-repeat 0 0;
  }
  &.JP {
    background: url(../assets/images/main/banner_ongoing_JP.png) no-repeat 0 0;
  }
  &.CN {
    background: url(../assets/images/main/banner_ongoing_CN.png) no-repeat 0 0;
  }
}

.banner21 {
  background-size: 100% 100% !important;
  &.KR {
    background: url(../assets/images/main/banner_ongoing_KR.png) no-repeat 0 0;
  }
  &.EN {
    background: url(../assets/images/main/banner_ongoing_EN.png) no-repeat 0 0;
  }
  &.JP {
    background: url(../assets/images/main/banner_ongoing_JP.png) no-repeat 0 0;
  }
  &.CN {
    background: url(../assets/images/main/banner_ongoing_CN.png) no-repeat 0 0;
  }
}
.banner22 {
  background-size: 100% 100% !important;
  &.KR {
    background: url(../assets/images/main/banner_online_KR.png) no-repeat 0 0;
  }
  &.EN {
    background: url(../assets/images/main/banner_online_EN.png) no-repeat 0 0;
  }
  &.JP {
    background: url(../assets/images/main/banner_online_JP.png) no-repeat 0 0;
  }
  &.CN {
    background: url(../assets/images/main/banner_online_CN.png) no-repeat 0 0;
  }
}
</style>
