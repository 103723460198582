<template>
  <form :action="klnetUrl" accept-charset="utf-8"
        method="post" name="frmPayment" id="frmPayment" target="iframe_payment"
  >
    <input type="hidden" id="STOREID" name="STOREID" :value="vi.storeid" />
    <input type="hidden" id="STORE_NAME" name="STORE_NAME" :value="vi.storeName" />
    <input type="hidden" id="BUSINESS_NO" name="BUSINESS_NO" :value="vi.businessNo" />
    <input type="hidden" id="CRYPTO_KEY" name="CRYPTO_KEY" :value="vi.cryptoKey" />
    <input type="hidden" id="STORE_URL" name="STORE_URL" :value="vi.storeUrl" />
    <input type="hidden" id="TX_USER_DEFINE" name="TX_USER_DEFINE" :value="vi.txUserDefine" />
    <input type="hidden" id="TRAN_NO" name="TRAN_NO" :value="vi.tranNo" />
    <input type="hidden" id="PRODUCTTYPE" name="PRODUCT_TYPE" :value="vi.productType" />
    <input type="hidden" id="GOODS_NAME" name="GOODS_NAME" :value="vi.goodsName" />
    <input type="hidden" id="AMT" name="AMT" :value="vi.amt" />
    <input type="hidden" id="CURRENCY" name="CURRENCY" :value="vi.currency" />
    <input type="hidden" id="QUANTITY" name="QUANTITY" :value="vi.quantity" />
    <input type="hidden" id="SALE_DATE" name="SALE_DATE" :value="vi.saleDate" />
    <input type="hidden" id="CUSTOMER_NAME" name="CUSTOMER_NAME" :value="vi.customerName" />
    <input type="hidden" id="CUSTOMER_EMAIL" name="CUSTOMER_EMAIL" :value="vi.customerEmail" />
    <input type="hidden" id="CUSTOMER_TEL" name="CUSTOMER_TEL" :value="vi.customerTel" />
    <input type="hidden" id="CUSTOMER_IP" name="CUSTOMER_IP" :value="vi.customerIp" />
    <input type="hidden" id="NOTICE_URL" name="NOTICE_URL" :value="vi.noticeUrl" />
    <input type="hidden" id="RETURN_URL" name="RETURN_URL" :value="vi.returnUrl" />
    <input type="hidden" id="COMPLETE_URL" name="COMPLETE_URL" :value="vi.completeUrl" />
    <input type="hidden" id="CANCEL_URL" name="CANCEL_URL" :value="vi.cancelUrl" />
    <input type="hidden" id="TRADE_TYPE" name="TRAN_TYPE" :value="vi.tranType" />
    <input type="hidden" id="ETC_DATA1" name="ETC_DATA1" :value="vi.etcData1" />
    <input type="hidden" id="ETC_DATA2" name="ETC_DATA2" :value="vi.etcData2" />
    <input type="hidden" id="ETC_DATA3" name="ETC_DATA3" :value="vi.etcData3" />
    <input type="hidden" id="BANK_CD" name="BANK_CD" :value="vi.bankCd" />
    <input type="hidden" id="LIMIT_YN" name=" LIMIT_YN" :value="vi.limitYn" />
    <input type="hidden" id="LIMIT_NAME" name="LIMIT_NAME" :value="vi.limitName" />
    <input type="hidden" id="REQ_NAME" name="BANK_CD" :value="vi.reqName" />
    <input type="hidden" id="START_DATE" name="START_DATE" :value="vi.startDate" />
    <input type="hidden" id="END_DATE" name="END_DATE" :value="vi.endDate" />
  </form>
</template>

<script>
import CalcIssue from '@/api/rest/settle/calcIssue'

export default {
  name: 'VirtualAccountKLNetPop',
  props: {
    parentInfo: {
      type: Object,
      default () {
        return {
          invIssNo: ''
        }
      }
    }
  },
  data () {
    return {
      klnetUrl: '',
      returnUrl: '',
      vi: {
        apiSeq: '',
        storeid: '',
        storeName: '',
        businessNo: '',
        cryptoKey: '',
        storeUrl: '',
        txUserDefine: '',
        tranNo: '',
        productType: '',
        goodsName: '',
        amt: '',
        currency: '',
        quantity: '',
        saleDate: '',
        customerName: '',
        customerEmail: '',
        customerTel: '',
        customerIp: '',
        noticeUrl: '',
        returnUrl: '',
        completeUrl: '',
        cancelUrl: '',
        tranType: '',
        etcData1: '',
        etcData2: '',
        etcData3: '',
        startDate: '',
        endDate: '',
        bankCd: '',
        limitYn: '',
        limitName: '',
        reqName: ''
      }
    }
  },
  created () {
    // 초기 화면 세팅
    this.init()
  },
  methods: {
    // 가상계좌 발급 화면 단계별 조회
    async init () {
      //const invIssNo = this.$route.query.invIssNo

      const invIssNo = window.localStorage.getItem('vaInvIssNo')

      if (this.$ekmtcCommon.isEmpty(invIssNo)) {
        await this.$ekmtcCommon.asyncAlertMessage({ message: 'Invoice Issue No is null!' })
        return
      }

      if (process.env.VUE_APP_MODE !== 'PRD') {
        this.klnetUrl = 'https://devpg-pay.plism.com/paymentgateway/pc/reqPay.do'
        this.returnUrl = 'https://stg-api.ekmtc.com/settle/settle/create-invoice/virtualAccount/result'
      } else {
        this.klnetUrl = 'https://pg-pay.plism.com/paymentgateway/pc/apiService.do'
        this.returnUrl = 'https://api.ekmtc.com/settlesettle/create-invoice/virtualAccount/result'
      }

      await CalcIssue.getVirtaulAccountInfo(invIssNo).then(response => {
        console.log('setVirtaulAccountInfo {}', response.data)

        this.vi = response.data
        this.vi.returnUrl = this.returnUrl
        this.vi.completeUrl = 'https://stg-www.ekmtc.com/#/virtualAccount/complete'
        this.vi.cancelUrl = 'https://stg-www.ekmtc.com/#/virtualAccount/cancel'
      }).catch(e => {
        console.log(e)
      })

      var form = document.frmPayment
      form.target = 'popup_payment'
      form.submit()
    }
  }
}
</script>

<style scoped>
</style>
